.maindashboard {
    padding: 0px 0;
    min-height: 100vh;

    .lowerdashboardmain {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 40px;
    }

    .sidebar {
        background: var(--sidebar-bg);
        width: 244px;
        max-width: 100%;
        // height: 717px;
        height: 100vh;
        display: block;
        position: relative;

        .logoutbtn {
            display: flex;
            width: 204px;
            padding: 14px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #FF0083;
            border: none;
            color: #FFF;
            font-size: 16px;
            line-height: 20px;
            position:absolute; 
            top: unset;
            bottom: 32px; 
            left:50%; 
            transform:translate(-50%, -50%); 
        }

        .sidebarheader {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            padding: 33px 22px;
            justify-content: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            gap: 8px;

            .sidebarhead {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: var(--navbar-head-text);
            }

            .logoside {
                width: 127px;
                height: 34px;
            }
        }

        .heading {
            font-style: normal;
            font-family: "Gsemibold";
            font-size: 22px;
            line-height: 26px;
            color: #777e91;
            padding: 30px 25px;
        }

        .nav-item {
            width: 100%;
        }

        .settings {
            border-top: 1px solid var(--sidebar-border) !important;
            border-bottom: 1px solid var(--sidebar-border) !important;
            padding: 30px 22px !important;
            margin-top: 15px;
        }

        .nav-link {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: #A9A9A9;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 22px;
            width: 100%;
            gap: 18px;
            border-radius: 0px;
            border: none;
            border-left: 3px solid transparent;

            .comingssontext {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 100%;
                text-align: center;
                text-transform: uppercase;
                color: #4C9540;
            }
        }

        .nav-link.active,
        .show>.nav-link {
            background: rgba(0, 240, 38, 0.06);
            border-left: 3px solid #00F026;
            color: var(--sidebar-pay-text);
        }

        .pink.active,
        .show>.pink {
            background: var(--sidebar-tab-pink) !important;
            border-left: 3px solid #FF0083 !important;
            color: #FF0083 !important;
        }
    }

    .content-side {
        background: transparent;
        padding: 30px 0px;
        position: relative;
        width: calc(100% - 324px);

        .tab-pane{
            padding-top: 27px;
        }
    }
}

@media (max-width:1199px) {
    .maindashboard1 {
        .profile {
            margin-left: 20px;
        }

        .share-btn {
            right: 20px !important;
        }
    }

    .maindashboard .content-side .fifth .headingfifth h6::before {
        width: 200px;
        left: -241px;
    }

    .maindashboard .content-side .fifth .headingfifth h6::after {
        width: 200px;
        right: -241px;
    }
}

.sidebar-icon {
    display: none;
}

@media (max-width: 991px) {
    .maindashboard .sidebar .logoutbtn{
        width: 90%;
    }
    .maindashboard .sidebar .sidebarheader {
        display: none !important;
    }

    .maindashboard .content-side .tab-pane{
        padding-top: 0px;
    }

    .maindashboard .content-side {
        padding-top: 80px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .maindashboard .sidebar {
        width: 100%;
        background: var(--login-bg);
        border-radius: 0px;
    }

    .maindashboard .content-side {
        width: 100%;
    }

    .maindashboard .sidebar .nav-link {
        flex-direction: row;
        justify-content: flex-start;
    }

    .maindashboard .sidebar .logoside {
        display: none;
    }

    .sidebar-icon {
        display: block !important;
        background: var(--login-bg);
        padding: 17px 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobilelogo {
            display: block !important;
            height: 24px;
            width: 90px;
        }

        .sidebarheader {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            align-content: flex-start;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 9px;

            .sidebarhead {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: #131313;
            }
        }

        .lefticons {
            display: flex;
            align-items: center;
            gap: 15px;
            position: absolute;
            top: 24px;
            right: 20px;
            z-index: 99;

            .notificationimg {
                position: relative;

                .mainnotificationdot {
                    position: absolute;
                    top: 0px;
                    right: 1px;
                }
            }
            .notificationicon {
                border-radius: 6px;
                border: 1px solid var(--border-switch);
                background: var(--login-bg);
                width: 40px;
                height: 40px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
        
                .notificationdot {
                    position: absolute;
                    top: 5px;
                    right: 8px;
                }
               button{
                background-color: transparent;
                border: none;
                &::after{
                    display: none !important;
                }
               } 
                .dropdown-menu{
                    right: 0 !important;
                    min-width: 374px;
                    background: var(--noti-bg);
                    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
                    border-radius: 15px;
                    padding: 15px;
                    .upper-div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 15px;
                        h6{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 130%;
                            color: var(--noti-text-head);
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            a{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 130%;
                                color: #FF0083;
                            }
                        }
                    }
                    .bottom-content{
                        .single-noti{
                            background: var(--noti-inactive-bg);
                            border: 1px solid var(--noti-inactive-border);
                            border-radius: 8px;
                            padding: 15px;
                            margin-bottom: 3px;
                            &.active{
                                background: var(--noti-active-bg) !important;
                                border: 1px solid var(--noti-active-border) !important;
                                border-radius: 8px;
                            }
                            h6{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--noti-inner-text);
                                margin-bottom: 6px;
                            }
                            p{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--noti-inner-text1);
                                margin-bottom: 10px;
                            }
                            .laterago{
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 130%;
                                color: var(--noti-inner-text2);
                                &.active{
                                    color: #FF0083 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .maindashboard .content-side .mainbtns {
        display: none;
    }

    .btn-success {
        display: block !important;
    }

    .maindashboard .close-btn {
        display: block !important;
    }

    .mobielSidebarHide {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        z-index: 99;
        width: 100%;
        animation-name: moveInleft;
        animation-duration: 2s;
    }

    @keyframes moveInleft {
        0% {
            opacity: 1;
            transform: translateX(300px);
        }

        100% {
            opacity: 0;
            transform: translateX(-100px);
        }
    }

    .mobielSidebarShow {
        position: fixed;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        background: #FAFAFA;

        &::-webkit-scrollbar {
            display: none !important;
        }

        left: 0%;
        z-index: 9999999;
        width: 100%;
        animation-name: moveInRight;
        animation-duration: 1s;
        padding: 0;
        height: 100%;

        .sidebarheader {
            display: flex !important;
            background: var(--login-bg);
            padding: 17px 20px;

            .sidebarheaderss {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                align-content: flex-start;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 9px;

                .sidebarhead {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.02em;
                    color: #131313;
                }
            }

            .sidebarheaderlogo {
                height: 24px;
                width: 90px;
            }

            .lefticons {
                display: flex;
                align-items: center;
                gap: 15px;
                position: absolute;
                top: 24px;
                right: 20px;
                z-index: 99;

                .notificationimg {
                    position: relative;

                    .mainnotificationdot {
                        position: absolute;
                        top: 0px;
                        right: 1px;
                    }
                }
            }
        }
    }

    @keyframes moveInRight {
        0% {
            opacity: 0;
            transform: translateX(-100px);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    .mobile-side-none {
        display: none;
    }

    .padd-md {
        padding: 0;
    }

    .maindashboard .back-btn {
        padding: 0 15px;
    }

    .maindashboard .top-heading {
        padding: 0 15px;
    }

    .mainprofile-right {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .sidebar-icon {
        position: absolute;
        top: 0;
        right: 0px;
        width: 100%;
        left: 0px;
    }

    .maindashboard .top-heading {
        margin-top: 50px;
    }

    .maindashboard .lowerdashboardmain {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .padd-md {
        padding: 0;
    }

    .maindashboard .content-side .first .cover .add-cover {
        bottom: unset;
        top: 20px;
    }

    .maindashboard .content-side .first .bottom-content .badges .btns-two .edit-btn {
        width: 75%;
    }

    .maindashboard .content-side .first .bottom-content .badges .btns-two .refresh-btn {
        width: 22%;
    }

    .maindashboard .bottom-btn {
        flex-direction: column;
    }

    .maindashboard .bottom-btn button {
        width: 100%;
    }

    .maindashboard .tile p {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .maindashboard .content-side .fifth .headingfifth h6::before {
        display: none;
    }

    .maindashboard .content-side .fifth .headingfifth h6::after {
        display: none;
    }

    .maindashboard .content-side .fifth .bottom-content .inner-card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }

    .maindashboard .content-side .fifth .bottom-content .inner-card .left {
        text-align: center;
        order: 2;
        margin-top: 20px;
    }

    .maindashboard .content-side .fifth .bottom-content .last-inline-text p {
        font-size: 14px;
    }

    .maindashboard .content-side .six .main-content h6 {
        text-align: center;
    }

    .maindashboard .content-side .six .main-content h6 {
        font-size: 20px;
    }

    .maindashboard .content-side .six .main-content p {
        font-size: 16px;
    }

    .maindashboard {
        padding: 0 !important;
    }

    .maindashboard .sidebar {
        height: 100%;
        padding-top: 42px;
    }

    .maindashboard .mainprofile-right .bottom-card .top-parent {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px 0;

    }
    .light-theme .sidebar-icon{
        background: linear-gradient(0deg, #FAFAFA 0%, #FAFAFA 100%), #FFF !important;
    }
}


.ytctctcctctcc {

    display: none !important;

}

.overview-section .nav-tabs .nav-item.show .nav-link, .overview-section .nav-tabs .nav-link.active{
    .not-act{
        display: none;
    }
    .yes-act{
        display: block !important;
    }
}