.settings-item-wrapper {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--login-bg);
  margin: 20px 0px;
  padding: 20px;

  .settings-header {
    width: 100%;

    h5 {
      color: var(--datepicker-cell-text);
      font-size: 16px;
      font-family: DM Sans;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.32px;
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 17px;

      p {
        display: flex;
        align-items: center;
        color: var(--common-text1);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
    }
  }

  .settings-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .settings-content {
      width: 50%;
    }

    .update-pass-btn {
      border-radius: 5px;
      border: 1px solid #ff0083;
      background: var(--button-bg1);
      display: flex;
      padding: 13px 17px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #ff0083;
      font-size: 16px;
      font-family: DM Sans;
      max-width: 201px;
      width: 100%;
    }

    .settings-action-btn {
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked+.slider {
        background-color: #ff0083;
      }

      input:focus+.slider {
        box-shadow: 0 0 1px #ff0083;
      }

      input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
}

// change-email

.change-email-inputs-wrapperr {
  margin-bottom: 10px !important;

  .email-code {
    color: #7c7c7c;
    font-size: 12px;
    text-align: left;
    width: 100%;
    // font-family: IBM Plex Sans;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.1px;
  }

  .security-verofcation {
    color: var(--common-text1);
    font-size: 16px;
    // font-family: IBM Plex Sans;
    font-weight: 500;
    line-height: 110%;
    margin: 25px 0px 10px;
    text-align: left;
    width: 100%;
  }

  .input-box-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .email-code {
      color: #a9a8a8;
      font-size: 12px;
      font-family: DM Sans;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.1px;
    }

    .resend-code {
      color: #ff0083;
      font-size: 12px;
      font-family: DM Sans;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.1px;
      min-width: 77px;
    }
  }

  .input-wrapper-box.email-verification {
    input {
      padding-left: 222px;
    }
  }

  .input-wrapper-box.email-verification-2 {
    input {
      padding-left: 190px;
    }
  }

  .input-wrapper-box.email-verification-3 {
    input {
      padding-left: 170px;
    }
  }

  .input-wrapper-box {
    width: 100%;
    margin: 14px 0px;
    position: relative;

    .get-code {
      color: #ff0083;
      font-size: 14px;
      // font-family: IBM Plex Sans;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.1px;
      position: absolute;
      right: 15px;
      top: 24px;
    }

    .input-lable-content {
      top: 18px;
      left: 15px;
      display: flex;
      position: absolute;
      align-items: center;
    }

    input {
      width: 100%;
      border-radius: 8px;
      background: var(--input-bg);
      border: 1px solid var(--input-border);
      padding: 18px 15px 18px 105px;
      color: var(--common-text1);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.1px;

      &::placeholder {
        color: #c0c0c0;
      }
    }

    .divider-line {
      width: 1px;
      height: 12px;
      margin-left: 12px;
      background-color: #a9a8a8;
    }
  }
}

// change email modal

.modal-wrapper-box {
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(5px);

  .modal-dialog {
    max-width: 524px;

    .modal-content {
      padding: 20px;
      border-radius: 5px;
      background: var(--login-bg);
      border: 1px solid var(--input-border);
      box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);

      .modal-body {
        padding-left: 0px;
        padding-right: 0px;
      }

      .modal-header {
        border-radius: 8px;
        border: 1px solid var(--input-border1);

        .modal-title {
          color: var(--checkbox);
          font-size: 18px;
          font-family: DM Sans;
          font-weight: 500;
          line-height: 110%;
        }
      }

      .modal-inner-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 40px;

        .war-img {
          margin: 30px 0px;
        }

        h4 {
          width: 70%;
          margin: auto;
          color: var(--datepicker-cell-text);
          text-align: center;
          font-size: 16px;
          font-family: DM Sans;
          font-weight: 900;
          line-height: 110%;
          text-transform: uppercase;
        }
      }

      .modal-footer {
        padding: 0px;
        border: none;
        justify-content: space-between;

        button {
          width: 48%;
        }

        .outline-btn {
          border-radius: 8px;
          border: 1px solid var(--input-border);
          background: var(--button-bg);
          display: flex;
          padding: 14px 81px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          color: #ff0083;
          font-size: 16px;
          font-family: DM Sans;
        }

        .fill-btn {
          border-radius: 8px;
          background: #ff0083;
          display: flex;
          padding: 14px 81px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          border: 1px solid #ff0083;
        }
      }
    }
  }

  .btn-closee {
    border-radius: 8px;
    background: #FF0083;
    border: none;
    width: 90%;
    margin: 0 auto;
    padding: 15px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 25px;
    display: block;
  }
}

// google-authenticator

.google-authenticator-btn {
  background: none;
  border: none;

  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }
}

.g-auth-moda-content {
  // width: 85%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  // gap: 20px;

  .successfullyDone-box {
    h2 {
      color: var(--datepicker-cell-text);
      text-align: center;
      font-size: 22px;
      font-family: DM Sans;
      font-weight: 500;
      line-height: 110%;
      margin-bottom: 10px;
    }

    p {
      color: #a9a8a8;
      text-align: center;
      font-size: 12px;
      font-family: DM Sans;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.1px;
    }
  }

  h4 {
    color: var(--datepicker-cell-text);
    text-align: center;
    font-size: 22px;
    font-family: DM Sans;
    font-weight: 500;
    line-height: 110%;
    width: 70%;
    margin: 0 auto 53px;
  }

  .or-text {
    margin: 17px auto;
  }

  .auth-id {
    color: #ff0083;
    text-align: center;
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 17px;
  }
}

// Responcive

@media screen and (max-width: 787px) {}

@media screen and (max-width: 600px) {
  .settings-item-wrapper {
    .settings-body {
      flex-direction: column;
      align-items: flex-start;

      .settings-content {
        width: 100%;
      }

      .settings-action-btn {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 320px) {}

.modal-header {
  .btn-close {
    background: url("../../Assests/close-circle.svg") !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.modal-inner-para-text {
  color: #7c7c7c;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  max-width: 348px;
  width: 100%;
}

.hvydvvvcvdvvc {
  display: flex;
  align-items: center;
  gap: 10px;

  .profile-img {
    width: 70px;
    height: 70px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
  }

  h5 {
    margin-bottom: 0 !important;
  }
}

.upload-img-profile {
  margin-bottom: 50px;

  p {
    color: var(--common-text1);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.1px;
    margin: 15px 0;
  }

  .upload {
    height: 206px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px dashed var(--input-border);
    background: var(--input-bg);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn-change-profile {
  border-radius: 8px;
  background: #ff0083;
  padding: 14px;
  border: none;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ffafa-modal {
  .parentinputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 19px;
    margin-top: 10px;

    .code {
      border-radius: 8px;

      background: var(--inputone-bg);
      border: 1px solid var(--input-border);
      padding: 20px 17.833px 19px 18px;
      // width: 57.83px;
      // height: 76px;
      width: 100%;
      color: #ff0083;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ff0083;
      font-size: 34px;
      font-weight: 700;
      line-height: 110%;
      text-transform: uppercase;
      text-align: center;

      &:focus {
        border: 1px solid var(--inputone-border);
      }

      &::placeholder {
        color: #ff0083;
        text-align: center;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        text-transform: uppercase;
      }
    }
  }

  .endbtn {
    button {
      border-radius: 8px;
      background: #ff0083;
      padding: 14px 81px;
      width: 100%;
      font-size: 16px;
      font-family: DM Sans;
      color: #ffffff;
      border: none;
      margin-top: 40px;
    }
  }

  .digitbtns {
    button {
      border-radius: 8px;
      background: var(--button-bg);
      padding: 14px 81px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-family: DM Sans;
      color: #717374;
      border: none;
      margin-top: 50px;
    }
  }
}

@media (max-width: 600px) {
  .ffafa-modal .parentinputs .code {
    padding: 17px 7px;
  }

  .ffafa-modal .parentinputs {
    gap: 10px;
    margin-top: 0px;
  }

  .ffafa-modal .parentinputs .code {
    width: 50px;
    height: 56px;
  }

  .ffafa-modal .digitbtns button {
    margin-top: 40px;
  }
}

@media (max-width: 390px) {
  .ffafa-modal .parentinputs .code {
    width: 100%;
    height: 56px;
  }
}

.update-user {
  margin: 40px 0;

  input {
    border-radius: 5px;
    border: 1px solid var(--input-border);
    background: var(--input-bg);
    padding: 18px 20px;
    width: 100%;
    color: var(--cardtext);
    padding-right: 51px;

    &::placeholder {
      color: #cfcfcf;
      font-size: 16px;
      font-weight: 500;
      line-height: 130%;
    }
  }
}

.gsvtyscvtvssc {
  color: #a9a8a8;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  // line-height: 100%;
  letter-spacing: 0.1px;
}