.checkout {
#SvgjsLine12866 {
    color: #FF0083 !important;
}
    .headingmain {
        padding: 25px 0px;
        color: var(--heading-text);
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.1px;
    }

    .maingraph {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: var(--login-bg);
        padding: 25px;

        .graphimg {
            width: 100%;
        }

        .graphtop {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding-bottom: 25px;

            .graphtext {
                color: var(--heading-text);
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.1px;
            }

            .dropdownss {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 15px;

                .webdropdown {

                    .dropdown-item:focus,
                    .dropdown-item:hover {
                        background: unset;
                        color: #000 !important;
                    }

                    .dropdown-toggle::after {
                        display: none;
                    }

                    .btn {
                        display: flex;
                        padding: 8px 12px;
                        align-items: center;
                        gap: 12px;
                        justify-content: center;
                        border-radius: 5px;
                        border: 1px solid var(--datepicker-border);
                        background: var(--login-bg);
                        color: var(--datepicker-text);
                        font-size: 12px;
                        font-weight: 500;
                        width: 165px;

                        &:focus {
                            border: 1px solid #FF0083;
                        }
                    }

                    .dropdown-menu {
                        width: 165px;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid var(--datepicker-border);
                        background: var(--login-bg);
                        padding: 8px 10px 0px;
                        max-width: 165px;
                        min-width: 165px;

                        .drop {
                            padding: 0px;
                            margin: 0px;
                            padding-bottom: 10px;
                            color: var(--datepicker-text);
                            font-size: 12px;
                            font-weight: 500;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 8px;
                            &:hover{
                                color: var(--datepicker-text) !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .table-responsive{
        .table {
            /* border: 1px solid red; */
            margin-bottom: 25px;
            border-radius: 10px;
            border: 1px solid var(--table-border-top);
        }
        th {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: var(--datepicker-cell-text);
            vertical-align: middle !important;
            border: none;
            padding: 15px 0;
            position: relative;
            white-space: nowrap;
            padding: 28px 100px 25px 20px;
        }
    
        td {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: var(--td-text);
            vertical-align: middle !important;
            border: none;
            border-top: 1px solid var(--table-border-top);
            padding: 0;
            padding: 30px 0;
            white-space: nowrap;
            padding: 28px 25px;
        }
    }
    .twice-drop{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        .drop-custom{
            .dropdown-toggle{
                border-radius: 5px;
                border: 1px solid var(--datepicker-border);
                background: var(--datepicker-bg);
                color: var(--datepicker-text);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 8px 12px;
            }
            .dropdown-menu{
                border-radius: 5px;
                border: 1px solid var(--datepicker-border);
                background: var(--datepicker-bg);
                padding: 0;
                min-width: 100%;
                a{
                    color: var(--datepicker-text);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 8px 12px;
                    &:hover{
                        background: var(--datepicker-bg);
                        color: var(--datepicker-text);
                    }
                    &:focus{
                        background: var(--datepicker-bg);
                        color: var(--datepicker-text);
                    }
                    &:active{
                        background: var(--datepicker-bg);
                        color: var(--datepicker-text);
                    }
                }
            }
        }
    }
}

@media(max-width:600px) {
    .checkout .mbldrpdwns {
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
    }

    // .checkout .webdropdown {
    //     display: none;
    // }

    .checkout .maingraph .graphtop .graphtext {
        font-size: 16px;
    }

    .checkout .maingraph {
        padding: 14px 8px;
    }

    .checkout .maingraph .graphtop {
        padding-bottom: 18px;
        flex-direction: column;
        gap: 15px;
    }
    .checkout .maingraph .graphtop .dropdownss{
        width: 100%;
    }

    .checkout .mbldropdown {
        display: block !important;
        width: 100%;

        .dropdown-item:focus,
        .dropdown-item:hover {
            background: unset;
            color: #000 !important;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .btn {
            display: flex !important;
            padding: 16px 12px;
            align-items: center;
            gap: 12px;
            justify-content: space-between;
            border-radius: 5px;
            border: 1px solid #E3E3E3;
            background: #FFF;
            color: var(--datepicker-text);
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            margin-bottom: 17px;
        }

        .dropdown-menu {
            width: 100%;
            cursor: pointer;
        }
    }
    .checkout .twice-drop{
        width: 100%;
        .dropdown{
            flex: 0 0 48%;
        }
    }
    .checkout .twice-drop .drop-custom .dropdown-toggle{
        width: 100%;
    }
}

.dropdownss {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 15px;

    .webdropdown {

        .dropdown-item:focus,
        .dropdown-item:hover {
            background: unset;
            color: #000 !important;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .btn {
            display: flex;
            padding: 8px 12px;
            align-items: center;
            gap: 12px;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid var(--datepicker-border);
            background: var(--login-bg);
            color: var(--datepicker-text);
            font-size: 12px;
            font-weight: 500;
            width: 165px;

            &:focus {
                border: 1px solid #FF0083;
            }
        }

        .dropdown-menu {
            width: 165px;
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid var(--datepicker-border);
            background: var(--login-bg);
            padding: 8px 10px 0px;
            max-width: 165px;
            min-width: 165px;

            .drop {
                padding: 0px;
                margin: 0px;
                padding-bottom: 10px;
                color: var(--datepicker-text);
                font-size: 12px;
                font-weight: 500;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                &:hover{
                    color: var(--datepicker-text) !important;
                }
            }
        }
    }
}

.twice-text-inner{
    flex-direction: column;
}

.custom-set-width{
    max-width: 390px !important;
    h5{
        font-size: 15px !important;
    }
}