.overviewnew-section {
  .multi-cards-parent {
    .main-card {
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: var(--overview-card-bg);
      padding: 20px;
      margin-bottom: 20px;

      .main-heading {
        margin-bottom: 20px;

        h6 {
          color: var(--navbar-head-text);
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }
      }

      .parent-cards-upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .inner-card {
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          background: var(--overview-tab-bg);
          padding: 24px;
          flex: 0 0 49%;

          p {
            color: var(--tab-card-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 19.2px */
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 18px;

            span {
              opacity: var(--opac);
            }
          }

          h6 {
            color: var(--navbar-head-text);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 24px */
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            gap: 16px;

            .green-text {
              border-radius: 6px;
              background: var(--tab-card-green);
              padding: 3px 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #30A01E;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: 51px;
              height: 27px;
            }

            .red-text {
              border-radius: 6px;
              background: var(--tab-card-red);
              padding: 3px 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #F83528;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: 51px;
              height: 27px;
            }
          }
        }

      }

      .parent-cards-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .inner-card {
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          background: var(--overview-tab-bg);
          padding: 24px;
          flex: 0 0 32.3%;

          p {
            color: var(--tab-card-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 19.2px */
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 18px;

            span {
              opacity: var(--opac);
            }
          }

          h6 {
            color: var(--navbar-head-text);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 24px */
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            gap: 16px;

            .green-text {
              border-radius: 6px;
              background: var(--tab-card-green);
              padding: 3px 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #30A01E;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: 51px;
              height: 27px;
            }

            .red-text {
              border-radius: 6px;
              background: var(--tab-card-red);
              padding: 3px 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #F83528;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: 51px;
              height: 27px;
            }
          }
        }

      }

      .tomipay-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .innermain-card {
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          background: var(--overview-tab-bg);
          padding: 24px;

          .inner-single {
            p {
              color: var(--tab-card-text);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 19.2px */
              display: flex;
              align-items: center;
              gap: 10px;
              margin-bottom: 18px;

              .addressIcon-img {
                width: 24px;
                height: 24px;
                object-fit: cover;
              }

              span {
                opacity: var(--opac);
              }
            }

            h6 {
              color: var(--navbar-head-text);
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              /* 24px */
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              gap: 16px;
              margin-bottom: 8px;

              .green-text {
                border-radius: 6px;
                background: var(--tab-card-green);
                padding: 3px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #30A01E;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 51px;
                height: 27px;
              }

              .red-text {
                border-radius: 6px;
                background: var(--tab-card-red);
                padding: 3px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #F83528;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 51px;
                height: 27px;
              }
            }

            .last-para {
              color: var(--tab-card-text);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              opacity: var(--opac);
            }
          }
        }

        .first-card {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 49%;
          width: 100%;
        }

        .second-card {
          max-width: 49%;
          width: 100%;
        }
      }

      .tomitdns-parent {

        .innermain-card {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .inner-single {
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            background: var(--overview-tab-bg);
            padding: 24px;
            flex: 0 0 24%;


            p {
              color: var(--tab-card-text);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 19.2px */
              display: flex;
              align-items: center;
              gap: 10px;
              margin-bottom: 18px;

              span {
                opacity: var(--opac);
              }
            }

            h6 {
              color: var(--navbar-head-text);
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              /* 24px */
              letter-spacing: -0.2px;
              display: flex;
              align-items: center;
              gap: 16px;
              margin-bottom: 8px;

              
            .green-text {
              border-radius: 6px;
              background: var(--tab-card-green);
              padding: 3px 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #30A01E;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: 51px;
              height: 27px;
            }

            .red-text {
              border-radius: 6px;
              background: var(--tab-card-red);
              padding: 3px 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #F83528;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              width: 51px;
              height: 27px;
            }

             
            }

            .twiceeeee-tex{
              .green-text {
                border-radius: 6px;
                background: var(--tab-card-green);
                padding: 3px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #30A01E;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // width: 51px;
                // height: 27px;
                display: inline-block;
              }

              .red-text {
                border-radius: 6px;
                background: var(--tab-card-red);
                padding: 3px 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #F83528;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // width: 51px;
                // height: 27px;
                display: inline-block;
              }
            }

            .last-para {
              color: var(--tab-card-text);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              opacity: var(--opac);
            }
          }
        }

      }

      .dop-parent {
        .innermain-card {
          flex-wrap: wrap;
          gap: 15px;

          .inner-single {
            flex: 0 0 23%;
            flex-grow: 1;
            height: 173px;
          }
        }
      }
    }
  }

  .last-para {
    color: var(--tab-card-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: var(--opac);
  }
}


@media (max-width:600px) {
  .overviewnew-section .multi-cards-parent .main-card .parent-cards-bottom {
    flex-direction: column;
    gap: 15px;
  }

  .overviewnew-section .multi-cards-parent .main-card .parent-cards-upper {
    flex-direction: column;
    gap: 15px;
  }

  .overviewnew-section .multi-cards-parent .main-card .parent-cards-upper .inner-card {
    width: 100%;
  }

  .overviewnew-section .multi-cards-parent .main-card .parent-cards-bottom .inner-card {
    width: 100%;
  }

  .overviewnew-section .multi-cards-parent .main-card .tomipay-parent {
    flex-direction: column;
    gap: 15px;
  }

  .overviewnew-section .multi-cards-parent .main-card .tomipay-parent .first-card {
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
    align-items: flex-start;
  }

  .overviewnew-section .multi-cards-parent .main-card .tomipay-parent .second-card {
    max-width: 100%;

  }

  .overviewnew-section .multi-cards-parent .main-card .tomitdns-parent .innermain-card {
    flex-direction: column;
    gap: 15px;
  }

  .overviewnew-section .multi-cards-parent .main-card .tomitdns-parent .innermain-card .inner-single {
    flex: 0 0 100%;
    width: 100%;
  }
  .overviewnew-section .multi-cards-parent .main-card .dop-parent .innermain-card .inner-single{
    height: auto;
  }
}

.twiceeeee-tex h6{
  font-size: 16px !important;
}