.notification-section{
    .parent-noti{
      
            left: unset !important;
            right: 0 !important;
            width: 100%;
            background: var(--noti-bg);
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            padding: 15px;
            .upper-div{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                h6{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;
                    color: var(--noti-text-head);
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    a{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 130%;
                        color: #FF0083;
                    }
                }
            }
            .bottom-content{
                .single-noti{
                    background: var(--noti-inactive-bg);
                    border: 1px solid var(--noti-inactive-border);
                    border-radius: 8px;
                    padding: 15px;
                    margin-bottom: 3px;
                    &.active{
                        background: var(--noti-active-bg) !important;
                        border: 1px solid var(--noti-active-border) !important;
                        border-radius: 8px;
                    }
                    h6{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--noti-inner-text);
                        margin-bottom: 6px;
                    }
                    p{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--noti-inner-text1);
                        margin-bottom: 10px;
                    }
                    .laterago{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 130%;
                        color: var(--noti-inner-text2);
                        &.active{
                            color: #FF0083 !important;
                        }
                    }
                }
        }
    }
  
}

.wrapper-noti{
    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 25px;

        .left-f {
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #767676;
            }
        }

        .right-f {
            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
                background-color: var(--login-bg);
                box-shadow: none;

                &.active {
                    background: #FF0083;
                    border-radius: 5px;
                    color: #fff;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
            }
        }
    }
}

@media (max-width:600px){
    .wrapper-noti .footer-content{
        padding: 14px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}