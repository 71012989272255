@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import "rsuite/dist/rsuite.css";

html {
  scroll-behavior: smooth;
}

.dark-theme {
  --body-bg: #0A0A0A;
  --body-bg1: #0A0A0A;
  --cardtext:#FFFFFF;
  --cardtextpara:#454545;
  --input-bg: #111;
  --input-border:#1C1C1C;
  --checkbox:#FFFFFF;
  --borderinput:#FF0083;
  --inputone-bg:#111;
  --inputone-border:#FF0083;
  --pinkcolor:#FF0083;
  --maintabsbrdr: #1C1C1C;
  --tablinks: #454545;
  --login-bg:#101010;
  --button-bg: #1C1E1F;
  --button-bg1: #1C1E1F;
  --border-switch: #060505;
  --heading-text: #FFFFFF;
  --graph-border: #1C1C1C;
  --sidebar-bg: #101010;
  --sidebar-tab-pink: rgba(255, 0, 131, 0.03);
  --sidebar-border: #1C1C1C;
  --sidebar-pay-text: #ffffff;

   // range date picker variables
  --datepiker-bg: #111111;
  --datepicker-border: #1C1C1C;
  --datepicker-text: #FFFFFF;
  --datepicker-menubg: #1A1A1A;
  --datepicker-cell-text: #FFFFFF;
  --datepicker-range-bg: #2E1723;
  


  // data table variables
  --common-text: #fff;
  --common-text1: #fff;
  --td-text: #7F7F7F;
  --input-bg1: #101010;
  --input-border1: #1C1C1C;
  --table-border-top: #1C1C1C;

  --navbar-head-text: #fff;

  --overview-tab-border: #1c1c1c;
  --overview-tab-bg: #0A0A0A;
  --tab-card-bg: #101010;
  --tab-card-border: #1C1C1C;
  --tab-card-text: #454545;
  --tab-card-green: rgba(137, 234, 121, 0.1);
  --tab-card-red: rgba(248, 53, 40, 0.10);

    // notification panel

    --noti-bg: linear-gradient(0deg, #1A1A1A, #1A1A1A), #FFFFFF;;
    --noti-text-head: #fff;
    --noti-inactive-bg: #191919;
    --noti-inactive-border: transparent;
    --noti-active-bg: #1F1F1F;
    --noti-active-border: #1C1C1C;
    --noti-inner-text: #fff;
    --noti-inner-text1:  #454545;
    --noti-inner-text2: #454545;


    --tooltip-bg: #1D1D1D;
    --tooltip-text: #fff;
    --opac: 1;

    --overview-card-bg: #101010;
}

.light-theme {
  --body-bg: #FFFFFF;
  --body-bg1: #FAFAFA;
  --cardtext: #000000;
  --cardtextpara:#A3A3A3;
  --input-bg: #FFF;
  --input-border:#EAEAEA;
  --checkbox:#151515;
  --borderinput:#FF0083;
  --inputone-bg:#FFF;
  --inputone-border:#FF0083;
  --pinkcolor:#FF0083;
  --maintabsbrdr: rgba(0, 0, 0, 0.05);
  --tablinks: #A9A9A9;
  --login-bg:#FFFFFF;
  --button-bg: #EAEBF0;
  --button-bg1: transparent;
  --border-switch: #FFDCEE;
  --heading-text: #171725;
  --graph-border: #F5F5F5;
  --sidebar-bg: #FFFAFD;
  --sidebar-tab-pink: #FFEDF7;
  --sidebar-border: #EFECEC;
  --sidebar-pay-text: #363435;

  // range date picker variables
  --datepiker-bg: #FFFFFF;
  --datepicker-border: #E3E3E3;
  --datepicker-text: #181818;
  --datepicker-menubg: #fff;
  --datepicker-cell-text: #2D3436;
  --datepicker-range-bg: #FFF7FB;

  // data table variables
  --common-text: #151515;
  --common-text1: #000000;
  --td-text: #797988;
  --input-bg1: #fff;
  --input-border1: #DFE6E9;
  --table-border-top: #F2F2F2;

  --navbar-head-text: #131313;

  --overview-tab-border: #EDEDED;
  --overview-tab-bg: #fff;
  --tab-card-bg: #fff;
  --tab-card-border: rgba(0,0,0,0.05);
  --tab-card-text: #181818;
  --tab-card-green: #EDFFEA;
  --tab-card-red: rgba(248, 53, 40, 0.10);


  // notification panel

  --noti-bg: #FFFFFF;
  --noti-text-head: #2C2C2C;
  --noti-inactive-bg: #FAFAFA;
  --noti-inactive-border: #FBFBFB;
  --noti-active-bg: #F1F1F1;
  --noti-active-border: #F3F3F3;
  --noti-inner-text: #1F1F1F;
  --noti-inner-text1: #828284;
  --noti-inner-text2: #6E6E73;

  --tooltip-bg: #fff;
    --tooltip-text: #44444F;
    --opac: 0.4;

    --overview-card-bg: #fdfdfd;

}

.maintabs {
  border-bottom: 1px solid var(--maintabsbrdr);
  max-width: fit-content;
  // margin-bottom: 25px;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar{
    display: none;
  }

  .nav-item {
    border-radius: 0px;

    .tablinks {
      display: flex;
      width: 148px;
      padding: 14px;
      justify-content: center;
      align-items: center;
      color: var(--tablinks);
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid transparent;
      border-radius: 0px;
    }

    .tablinks.active,
    .show>.tablinks {
      background-color: transparent;
      color: #FF0083;
      border-bottom: 2px solid #FF0083;
    }
  }
}

body {
  background: var(--body-bg);
  font-family: 'DM Sans', sans-serif;
  min-height: 100vh;
  padding: 0px;
  margin: 0px;
}

iframe {
  display: none !important;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
  .padd-sm{
    padding: 0 !important;
  }
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

// calnder range picker scss here......................
  .rs-picker-menu{
    // top: 243.05px !important;
    // right: 66px !important;
    // left: unset !important;
    background: var(--datepicker-menubg) !important;
    border: 1px solid var(--datepicker-border) !important;
    border-radius: 5px !important;
    box-shadow: unset !important;
  }

  .rs-picker-daterange-header{
    display: none !important;
  }

  .rs-calendar-table-cell-content{
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px !important;
    // letter-spacing: 0.03em;
    color: var(--datepicker-cell-text);
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
        background: transparent !important;
        border-radius: 7px !important;
        border: 2px solid #FF0083 !important;
        box-shadow: none !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        // letter-spacing: 0.03em;
        color: #FF0083;
  }

  .rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled{
    background: #FF0083;
    border-radius: 8px;
  }

  .rs-btn-primary{
    background: #FF0083;
    border-radius: 8px;
  }

  .rs-picker-toggle{
    background: var(--datepiker-bg);
    border: 1px solid var(--datepicker-border);
    border-radius: 5px;
  }

  .rs-picker-toggle:hover, .rs-picker-toggle:focus{
    background: var(--datepiker-bg);
    border: 1px solid var(--datepicker-border);
    border-radius: 5px;
  }

  .rs-picker-toggle .rs-picker-toggle-placeholder{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--datepicker-text);
  }

  .rs-picker-toggle-active, .rs-picker.rs-picker-focused{
    box-shadow: none !important;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    background: #FF0083 !important;
        border-radius: 7px !important;
        border: none !important;
        box-shadow: none !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        // letter-spacing: 0.03em;
        color: #FFFFFF;
  }

  .rs-calendar-table-cell-content:hover{
    background: #FF0083 !important;
        border-radius: 7px !important;
        border: none !important;
        box-shadow: none !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        // letter-spacing: 0.03em;
        color: #FFFFFF;
  }

  .rs-calendar-table-cell-in-range::before{
    background: var(--datepicker-range-bg);
  }

  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content{
    color: #fff;
  }

  .rs-btn-subtle{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    // letter-spacing: 0.02em;
    color: var(--datepicker-cell-text);
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--datepicker-text);
  }

  .rs-btn-link{
    color: #ff0083;
  }
  .rs-picker-toggle:active, .rs-picker-toggle.rs-btn-active{
    background-color: unset !important;
  }
  .rs-picker-toggle-textbox{
    background-color: transparent !important;
  }
  .rs-picker-daterange-menu .rs-calendar:first-child{
    border-right: 1px solid var(--datepicker-border);
  }
  .rs-picker-toolbar{
    border-top: 1px solid var(--datepicker-border);
  }

  .rs-picker-toggle-textbox{
      display: none !important;
    
  }

  @media (max-width:600px){
    .rs-picker-daterange-calendar-group{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: auto;
    }
    // .rs-picker-daterange-menu .rs-calendar:first-child{
    //   min-width: 300px;
    // }
    // .rs-picker-menu{
    //   top: 335px !important;
    //   right: 30px !important;
    // }
    .rs-picker-menu .rs-calendar{
      min-width: 300px !important;
      margin: 0 auto;
    }
    .rs-picker-daterange-calendar-group{
      min-width: 300px;
    }
    .rs-picker-daterange-menu .rs-calendar:first-child{
      border: none !important;
    }
  }



  // custom checkbox...................
  .chec {
    .material-checkbox {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: #151515;
    }

    .material-checkbox input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    .checkmark {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid var(--datepicker-border);
        border-radius: 5px;
        transition: all 0.3s;
    }

    .material-checkbox input[type="checkbox"]:checked~.checkmark {
        background-color: #ff0083;
        border-color: #ff0083;
    }

    .material-checkbox input[type="checkbox"]:checked~.checkmark:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 6px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .material-checkbox input[type="checkbox"]:focus~.checkmark {
        box-shadow: 0 0 0 2px ff0083;
    }

    .material-checkbox:hover input[type="checkbox"]~.checkmark {
        border-color: ff0083;
    }

    .material-checkbox input[type="checkbox"]:disabled~.checkmark {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .material-checkbox input[type="checkbox"]:disabled~.checkmark:hover {
        border-color: #ff0083;
    }

}

@media (max-width:600px){
  .btn-back{
    top: 110px !important;
    left: 20px !important;
  }
}

.dark-theme{
  .showfordark{
    display: block !important;
  }
  .nonefordark{
    display: none !important;
  }
}

.page-item.active .page-link {
  z-index: 3!important;
  color: #fff!important;
  background: #FF0083!important;
  border-radius: 5px!important;
}

.texrcenter {
  // text-align: center;
  margin-bottom: 20px;
}
.dark-theme .light-icon-tomitoken{
  display: none !important;
}
.dark-theme .dark-icon-tomitoken{
  display: block !important;
}

.bvb{
  pointer-events: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

.apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker{
  display: unset!important;
}


.apexcharts-text tspan{
  fill: var(--datepicker-cell-text) !important;
}


.dark-theme .light-theme-logo{
  display: none;
}

.light-theme .dark-theme-logo{
  display: none;
}

@media(max-width:600px){
  .datatableLiq .table-responsivecolorbg .table-responsive{
    display: none !important;
  }
  .checkout .table-responsive{
    display: none !important;
  }
}