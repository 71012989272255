.domaindetails-table{
    .accmblview .accordion .accordion-item .accordion-header .accordion-button .accheadermain .accmainimage{
        width: 59px;
        height: 59px;
        border-radius: 3px !important;
    }
    .accmblview .accordion .accordion-item .accordion-header .accordion-button .accheadermain .accmainimage .accinnerimg{
        border-radius: 3px !important;
    }
}

.twicee-abs{
    position: relative;
    width: 60px;
    height: 60px;
    img{
        object-fit: cover;
    }
    span{
        position: absolute;
        bottom: 0px;
        right: 5px;
        color: #fff;
        font-size: 7px;
    }
}