.user-chart-wrapper {

    .apexcharts-menu.apexcharts-menu-open {
        opacity: 1;
        pointer-events: all;
        background: linear-gradient(89.96deg, rgba(255, 0, 131, 0.04) -0.98%, rgba(156, 0, 255, 0.04) 100%) !important;
        transition: 0.15s ease all;
    }

    #SvgjsDefs1002 {
        background: linear-gradient(89.96deg, rgba(255, 0, 131, 0.04) -0.98%, rgba(156, 0, 255, 0.04) 100%) !important;
    }

    // .apexcharts-toolbar {
    //     // display: ;
    // }

    // .stabl3graph {
    //     border-radius: 0 0 10px 10px !important;
    // }

    .treasure-graph {
        height: 91.5% !important;

    }

    .treasure-graph svg {
        margin-top: 80px;
    }


    //Guage Chart

    .App-link {
        color: #FF0083;
    }

    /* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
    #gauge-chart6 {
        height: 90% !important;
        background: linear-gradient(89.96deg, rgba(255, 0, 131, 0.04) -0.98%, rgba(156, 0, 255, 0.04) 100%);
        border: 1px solid #6F6ED4;
        backdrop-filter: blur(17px);
        border-radius: 0 0 10px 10px;
    }

    #gauge-chart6 svg {
        height: 100%;
    }

    #gauge-chart6 svg g .text-group text {
        transform: translateY(2.2in);
        font-weight: 600;
    }


    .apexcharts-tooltip {
        color: #fff !important;
    }

    @media (max-width:1199px) {
        .treasure-graph svg {
            margin: 0 auto;
        }
    }

    @media (max-width:991px) {
        #gauge-chart6 {
            height: auto !important;
        }
    }

    @media (max-width:600px) {
        #gauge-chart6 svg g .text-group text {
            transform: translate(0) !important;
        }

        // .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
        //   overflow: unset !important;

        // }
        .apexcharts-legend {
            overflow: unset !important;
            padding: 0 !important;
            justify-content: flex-start !important;
            align-items: center;
        }
    }


    .apexcharts-menu-icon {
        display: none !important;
    }

    .apexcharts-reset-icon {
        display: none !important;
    }

    .apexcharts-pan-icon {
        display: none !important;
    }

    .apexcharts-zoom-icon.apexcharts-selected {
        display: none !important;
    }

    .apexcharts-toolbar {
        display: none;
    }


    .apexcharts-tooltip.apexcharts-theme-light {
        // border: 1px solid #e3e3e3;
        background: rgba(255, 255, 255, .96) !important;
        box-shadow: 0px 3.0565693378448486px 45.848541259765625px 0px #44444F1A;
        // top: -35px !important;
        // left: 670.572px !important;
    }

    .apexcharts-tooltip {
        color: var(--tooltip-text) !important;
        background-color: var(--tooltip-bg) !important;
        box-shadow: 0px 3.0565693378448486px 45.848541259765625px 0px #44444F1A;
    }

    .apexcharts-tooltip-title{
        font-size: 14px !important;
        font-weight: 700 !important;
        text-align: center !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }

    .apexcharts-tooltip-marker{
        display: none;
    }
}