.error-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;

    .btn-back-error{
        border-radius: 130px;
        border: 1px solid rgba(255, 0, 131, 0.10);
        background: rgba(255, 0, 131, 0.05);
        padding: 10px 15px;
        color: #FF0083;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}