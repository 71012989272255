.main-login {
  background: url(../../src/Assests/bg-logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 150px;

  .maincard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    .logincard {
      padding: 35px 50px;
      border-radius: 20px;
      width: 100%;
      max-width: 542px;
      border-radius: 20px;
      background: var(--login-bg);
      box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);

      .innerlogo {
        text-align: center;
      }

      .cardtext {
        h6 {
          font-size: 22px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: var(--cardtext);
          text-align: center;
          margin-top: 50px;
          margin-bottom: 15px;
          text-transform: uppercase;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: var(--cardtextpara);
          text-align: center;
          margin-bottom: 40px;
        }

        input {
          border-radius: 5px;
          border: 1px solid var(--input-border);
          background: var(--input-bg);
          padding: 18px 20px;
          width: 100%;
          max-width: 442px;
          margin-bottom: 5px;
          color: var(--cardtext);
          padding-right: 51px;

          &::placeholder {
            color: #cfcfcf;
            font-size: 16px;
            font-weight: 500;
            line-height: 130%;
          }
        }
        .error-margin {
          margin-bottom: 12px;
        }
        .parent {
          position: relative;
          .eye-btn {
            cursor: pointer;
          }
          img {
            position: absolute;
            top: 20px;
            right: 20px;
          }
        }
      }

      .chec {
        .material-checkbox {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #fff;
          font-size: 14px;
          color: var(--cardtext);
        }

        .material-checkbox input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
        }

        .checkmark {
          position: relative;
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 12px;
          border: 2px solid #ff0083;
          border-radius: 5px;
          transition: all 0.3s;
        }

        .material-checkbox input[type="checkbox"]:checked ~ .checkmark {
          background-color: #ff0083;
          border-color: #ff0083;
        }

        .material-checkbox input[type="checkbox"]:checked ~ .checkmark:after {
          content: "";
          position: absolute;
          top: 2px;
          left: 6px;
          width: 4px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        .material-checkbox input[type="checkbox"]:focus ~ .checkmark {
          box-shadow: 0 0 0 2px ff0083;
        }

        .material-checkbox:hover input[type="checkbox"] ~ .checkmark {
          border-color: ff0083;
        }

        .material-checkbox input[type="checkbox"]:disabled ~ .checkmark {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .material-checkbox input[type="checkbox"]:disabled ~ .checkmark:hover {
          border-color: #ff0083;
        }
      }

      .parentinputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 19px;
        margin-top: 10px;

        .code {
          border-radius: 8px;

          background: var(--inputone-bg);
          border: 1px solid var(--input-border);
          padding: 20px 11px 19px 11px;
          // width: 57.83px;
          // height: 76px;
          width: 100%;
          color: #ff0083;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #ff0083;
          font-size: 34px;
          font-weight: 700;
          line-height: 110%;
          text-transform: uppercase;
          text-align: center;

          &:focus {
            border: 1px solid var(--inputone-border);
          }

          &::placeholder {
            color: #ff0083;
            text-align: center;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
          }
        }
      }

      .endbtn {
        button {
          border-radius: 8px;
          background: #ff0083;
          padding: 14px 81px;
          width: 100%;
          font-size: 16px;
          font-family: DM Sans;
          color: #ffffff;
          border: none;
          margin-top: 40px;
        }
      }

      .digitbtns {
        button {
          border-radius: 8px;
          background: var(--button-bg);
          padding: 14px 81px;
          width: 100%;
          color: #fff;
          font-size: 16px;
          font-family: DM Sans;
          color: #717374;
          border: none;
          margin-top: 50px;
        }
      }
    }
  }
  .bottom-text{
    margin-top: 25px;
    h6{
      color: #A3A3A3;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; 
      a{
        color: #ff0083;
      }
    }
  }
}

@media (max-width: 600px) {
  .main-login .maincard .logincard .cardtext h6 {
    font-size: 16px;
    margin-top: 40px;
  }

  .main-login .maincard .logincard .parentinputs .code {
    padding: 17px 7px;
  }

  .main-login .maincard .logincard .parentinputs {
    gap: 10px;
    margin-top: 0px;
  }

  .main-login .maincard .logincard .digitbtns button {
    margin-top: 40px;
  }

  .main-login .maincard .logincard {
    padding: 20px !important;
  }

  .main-login .maincard .logincard .parentinputs .code {
    width: 50px;
    height: 56px;
  }
}

@media (max-width: 390px) {
  .main-login .maincard .logincard .parentinputs .code {
    width: 100%;
    height: 56px;
  }
}

.navbar-login {
  background: var(--login-bg) !important;
  box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04) !important;
  padding: 25px;

  .sidebarheader {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    .sidebarhead {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: var(--navbar-head-text);
    }

    .logoside {
      width: 127px;
      height: 34px;
    }
  }
}

@media (max-width: 991px) {
  .navbar-login .navbar .navbarlefticons {
    display: flex !important;
  }
  .navbar-login .navbar {
    padding: 0 !important;
  }
  .ytstcftfsctfscf {
    width: 95px;
  }
}

.twice-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: #ff0083;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.recover2fa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h6 {
    color: var(--common-text1);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    max-width: 305px;
    width: 100%;
    margin: 0 auto;
  }
}


.set-new-modal-for-2fa{
  .update-passowrd-wrapper .update-passowrd-content-wrapper .update-passowrd-content{
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
    background: transparent !important;
  }
  .update-passowrd-wrapper{
    min-height: 100% !important;
  }
  .modal-body{
    background-color: var(--body-bg);
  }
  .modal-content{
    background-color: transparent !important;
    border: none !important;
    overflow: hidden !important;
  }
}