.token-holder-chart-item1 {
    background: var(--login-bg);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 34px;

    .chart-wrap {
        #chart {
            .apexcharts-canvas {
                margin: 0 auto !important;
            }
        }
    }

    .apexcharts-legend {
        display: none;
    }
    .tomiholder-text{
        color: var(--cardtext);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.1px;
    }
}
.token-holder-chart-item {
  

    .chart-wrap {
        #chart {
            .apexcharts-canvas {
                margin: 0 auto !important;
            }
        }
    }

    .apexcharts-legend {
        display: none;
    }
}

@media (max-width:600px){
    .token-holder-chart-item{
        padding: 15px !important;
    }
}



.apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child{
    border-radius: 5px !important;
    background: var(--tooltip-bg) !important;
    box-shadow: 0px 3.0565693378448486px 45.848541259765625px 0px rgba(68, 68, 79, 0.10) !important;
    color: var(--tooltip-text) !important;
    font-family: DM Sans !important;
}

.rewardclaimchart{
    .token-holder-chart-item{
        border: none !important;
    }
}

.overview-section{
    .token-holder-chart-item1 {
        border: none !important;
    }
    .tomiholder-text{
        display: none !important;
    }
}

.tomiholder-table-text{
    color: var(--cardtext);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.1px;
        margin-bottom: 20px;
}