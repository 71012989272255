.datatable {
    .table-responsivecolorbg {
        padding: 0px 0px 0px 0px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: var(--login-bg);
    }

    .upper-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        padding: 28px 25px;

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: var(--common-text);
            }

            .dropdown-toggle {
                background: var(--login-bg);
                border: 1.5px solid var(--table-border-top);
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: var(--common-text);
                margin: 0 15px;
            }

            .dropdown {
                .dropdown-menu {
                    width: 100%;

                    a {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        color: var(--common-text);

                        &:hover {
                            background-color: #FF0083 !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }

        .right {
            .option-field {
                display: flex;
                align-items: center;
                justify-content: center;

                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    color: var(--common-text);
                    margin-right: 15px;
                }

                input {
                    background: var(--input-bg1);
                    border: 1px solid var(--input-border1);
                    border-radius: 7px;
                    max-width: 201px;
                    width: 100%;
                    height: 48px;
                }
            }

        }
    }

    th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: var(--datepicker-cell-text);
        vertical-align: middle !important;
        border: none;
        padding: 15px 0;
        position: relative;
        white-space: nowrap;
        padding: 28px 25px;
    }

    td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: var(--td-text);
        vertical-align: middle !important;
        border: none;
        border-top: 1px solid var(--table-border-top);
        padding: 0;
        padding: 30px 0;
        white-space: nowrap;
        padding: 28px 25px;
    }

    .textblack {
        display: flex;
        align-items: center;
        gap: 12px;
        color: var(--common-text1);
        font-size: 14px;
        font-weight: 500;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 25px;

        .left-f {
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #767676;
            }
        }

        .right-f {
            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
                background-color: var(--login-bg);
                box-shadow: none;

                &.active {
                    background: #FF0083;
                    border-radius: 5px;
                    color: #fff;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
            }
        }
    }
}

.filter-btn {
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width:600px) {
    .datatable .footer-content .left-f h6{
        display: none;
    }
    .accmblview {
        display: block !important;
        .accmblviewhead {
            color: var(--datepicker-cell-text);
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 25px 28px;

            .arrowsacc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                background: url("../../Assests/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;

                    .accordion-button {
                        border-top: 1px solid var(--table-border-top);
                        border-bottom: 1px solid var(--table-border-top);
                        background: var(--login-bg);
                        padding: 22px 25px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 12px;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: var(--common-text1);
                                font-size: 14px;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    // padding: 20px 25px;
                    padding: 0;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 25px;
                        border-top: 1px solid var(--table-border-top);
                        // margin-bottom: 40px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            font-size: 14px;
                            color: var(--common-text1);
                            font-weight: 500;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                        }

                        .textright {
                            color: var(--td-text);
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: 0.1px;
                        }
                    }
                }
            }
        }
    }

    .datatable .table-responsivecolorbg {
        .table-responsive {
            display: none;
        }
    }

    .datatable {
        td {
            text-align: center;
        }

        th {
            text-align: center;
        }
    }

    .datatable .upper-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .right {
            width: 100%;

            .option-field {
                width: 100%;
            }
        }
    }

    .datatable .footer-content {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .accmblview .accordion .accordion-item .accordion-header .accordion-button{
        box-shadow: none !important;
    }
}

.ssss{
    width: 40px!important;
}