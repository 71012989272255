.update-passowrd-wrapper {
    background: var(--body-bg1);
    min-height: 100vh;
    height: 100%;

    .update-passowrd-navbar {
        padding: 20px;
        background: #FFF;
        box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);
    }

    .update-passowrd-content-wrapper {
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        // height: calc(100vh - 80px);
        gap: 10px;

        .update-passowrd-content {
            display: flex;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 20px;
            background: var(--login-bg);
            padding: 35px 50px;
            max-width: 542px;
            width: 100%;
            margin: 65px auto;
            min-height: 550px;
            box-shadow: 0px 3px 50px 0px rgba(0, 0, 0, 0.04);

            .Alogo {
                // width: 100px;
                // height: 40px;
                margin: 0 auto 20px;
                margin-bottom: 50px !important;
            }

            .ABanner {
                // width: 138px;
                // height: 138px;
                margin: 0 auto 20px;
            }

            h4 {
                color: var(--datepicker-text);
                font-size: 22px;
                font-family: DM Sans;
                font-weight: 700;
                line-height: 110%;
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            p {
                color: #A3A3A3;
                text-align: center;
                font-size: 14px;
                font-family: DM Sans;
                font-weight: 500;
                line-height: 130%;
            }

            input {
                border-radius: 10px;
                border: 1.5px solid var(--input-border);
                background: var(--input-bg);
                // display: flex;
                width: 100%;
                padding: 18px 30px 18px 20px;
                padding-right: 80px;
                // align-items: center;
                margin: 40px auto;

                &::placeholder {
                    color: #CFCFCF;
                    font-size: 16px;
                    font-family: DM Sans;
                    font-weight: 500;
                    line-height: 130%;
                }
            }

            .fill-btn {
                border-radius: 8px;
                background: #FF0083;
                width: 100%;
                color: #FFF;
                font-size: 16px;
                font-family: DM Sans;
                padding: 14px 81px;
            }

            // 
            .pass-rules {
                width: 100%;
                display: flex;
                text-align: left;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                h6 {
                    margin-bottom: 12px;
                    color: #151515;
                    font-size: 14px;
                    font-family: DM Sans;
                    font-weight: 700;
                }

                li {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #A3A3A3;
                    font-size: 14px;
                    font-family: DM Sans;
                    font-weight: 500;

                    .passdot {
                        width: 6px;
                        height: 6px;
                        margin-right: 6px;
                        border-radius: 6px;
                        background: #FF0083;
                    }
                }

                .red-list {
                    color: #FE0000;
                    font-size: 14px;
                    font-family: DM Sans;
                    font-weight: 500;

                    .passdot {
                        background: #FE0000;
                    }
                }

                .green-list {
                    color: #3EC74C;
                    font-size: 14px;
                    font-family: DM Sans;
                    font-weight: 500;

                    .passdot {
                        background: #3EC74C;
                    }
                }
            }
        }
        .option-field{
            position: relative;
            width: 100%;
            .password-icon{
                position: absolute;
                top: 57px;
                right: 16px;
                z-index: 9999;
            }
        }
    }
}

@media (max-width:600px){
    .update-passowrd-wrapper .update-passowrd-content-wrapper{
        margin: 0 20px;
    }
    .update-passowrd-wrapper .update-passowrd-content-wrapper .update-passowrd-content{
        padding: 20px;
    }
    .update-passowrd-wrapper .update-passowrd-content-wrapper .update-passowrd-content h4{
        font-size: 16px;
    }
    .change-email-inputs-wrapperr .input-wrapper-box .get-code{
        top: 70px;
        right: 2px;
    }
    .change-email-inputs-wrapperr .input-box-row{
        margin-top: 46px;
    }
    .g-auth-moda-content h4{
        font-size: 16px;
        width: 95%;
    }
}

.btn-back{
    position: absolute;
    top: 150px;
    left: 70px;
    z-index: 99;
    button{
        border-radius: 130px;
        border: 1px solid rgba(255, 0, 131, 0.10);
        background: rgba(255, 0, 131, 0.05);
        padding: 10px 15px;
        color: #FF0083;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}