.newactiveuser {
    .maingraph {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: var(--login-bg);
        padding: 25px;

        .graphimg {
            width: 100%;
        }

        .graphtop {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding-bottom: 25px;

            .graphtext {
                color: var(--heading-text);
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.1px;
            }

            .webdropdown {

                .dropdown-item:focus,
                .dropdown-item:hover {
                    background: unset;
                    color: #000 !important;
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .btn {
                    display: flex;
                    padding: 8px 12px;
                    align-items: center;
                    gap: 12px;
                    justify-content: center;
                    border-radius: 5px;
                    border: 1px solid #E3E3E3;
                    background: #FFF;
                    color: #181818;
                    font-size: 12px;
                    font-weight: 500;
                    width: 110px;
                    &:focus{
                        border: 1px solid #FF0083;
                    }
                }

                .dropdown-menu {
                    width: 100%;
                    cursor: pointer;
                    border-radius: 5px;
                    border: 1px solid #E3E3E3;
                    background: #FFF;
                    padding: 8px 10px 0px;

                    .drop {
                        padding: 0px;
                        margin: 0px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}
.graphtext {
    color: var(--heading-text);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.1px;
}
@media(max-width:600px) {
    .newactiveuser .webdropdown {
        display: none;
    }

    .newactiveuser .maingraph .graphtop .graphtext {
        font-size: 16px;
    }

    .newactiveuser .maingraph {
        padding: 14px 8px;
    }

    .newactiveuser .maingraph .graphtop {
        padding-bottom: 18px;
        flex-direction: column;
        gap: 15px;
    }
    .rs-picker-toggle{
        min-width: 100% !important;
    }
    .rs-picker-toggle-wrapper{
        width: 100%;
    }
    .rs-stack{
        width: 100%;
    }
    .custom-daterangepicker{
        width: 100%;
    }

    .newactiveuser .mbldropdown {
        display: block !important;
        width: 100%;

        .dropdown-item:focus,
        .dropdown-item:hover {
            background: unset;
            color: #000 !important;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .btn {
            display: flex !important;
            padding: 16px 12px;
            align-items: center;
            gap: 12px;
            justify-content: space-between;
            border-radius: 5px;
            border: 1px solid #E3E3E3;
            background: #FFF;
            color: #181818;
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            margin-bottom: 17px;
        }

        .dropdown-menu {
            width: 100%;
            cursor: pointer;
        }
    }
}


.tomi-holder{
    .donut-chart{
        margin-bottom: 25px;
    }
}


.parent-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .right-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 300px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid var(--tab-card-border);
      padding: 11px 10px;
  
      .inner-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
  
        .inner-text {
          h6 {
            color: var(--datepicker-text);
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
          }
  
          p {
            color: var(--datepicker-text);
            font-size: 12px;
            line-height: 120%;
          }
        }
      }
  
      .inner-right {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
  
        h5 {
          color: var(--navbar-head-text);
          font-size: 19px;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: -0.19px;
        }
  
        .green-p {
          color: #30a01e;
          font-size: 12px;
          border-radius: 6px;
          background: var(--tab-card-green);
          padding: 3px 9px;
        }
        .red-p {
          color: #b84242;
          font-size: 12px;
          border-radius: 6px;
          background: #ffeaf0;
          padding: 3px 9px;
        }
      }
    }
  }


  @media (max-width: 600px) {
    .parent-overview {
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      // gap: 25px;
      display: block;
    }
    .maintabs{
      margin-bottom: 25px;
    }
    .overview-section .nav-tabs{
      margin-bottom: 25px;
    }
    .parent-overview .right-tab {
      max-width: 100%;
    }
  }