.main-navbar {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        margin: 0px;

        .navbarlefticons {
            display: flex;
            align-items: flex-start;
            gap: 15px;

            label {
                height: 40px;
                width: 76px;
                padding: 4px;
                position: relative;
                display: block;
                border-radius: 30px;
                border: 1px solid var(--border-switch);
                background: var(--login-bg);
                cursor: pointer;
                transition: 0.3s;
            }
            label:after {
                content: "";
                display: flex;
                width: 32px;
                height: 32px;
                justify-content: center;
                align-items: center;
                position: absolute;
                top:4px;
                left:4px;
                border-radius: 30px;
                background: #FF0083;
                box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.10);
                transition: 0.3s;
            }
            input {
                width: 0;
                height: 0;
                visibility: hidden;
            }
            input:checked + label:after {
                left:70px;
                transform: translateX(-100%);
            }

            // .background {
            //     width:100vw;
            //     height: 100vh;
            //     background: #fff;
            //     z-index: -1;
            //     position: absolute;
            //     transition: 0.3s;
            // }
            input:checked + label + .background {
                background:#242424;
            }
            label svg {
                position: absolute;
                top:11px;
                z-index: 100;
            }
            label svg.sun {
                left:11px;
                fill:#fff;
                transition: 0.3s;
            }
            label svg.moon {
                right:12px;
                fill:#7e7e7e;
                transition: 0.3s;
            }
            input:checked + label svg.sun {
                fill:#7e7e7e;
                stroke: #7e7e7e;
            }
            input:checked + label svg.moon path {
                fill:#FFFFFF;
            }

            .profileicon {
                display: flex;
                width: 40px;
                height: 40px;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                background: var(--login-bg);

                .mainprofileimg {
                    border-radius: 60px;
                    width: 27px;
                    height: 27px;
                    object-fit: cover;
                    object-position: center;
                    .profileimg{
                        border-radius: 60px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            .notificationicon {
                border-radius: 6px;
                border: 1px solid var(--border-switch);
                background: var(--login-bg);
                width: 40px;
                height: 40px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .notificationdot {
                    position: absolute;
                    top: 5px;
                    right: 8px;
                }
               button{
                background-color: transparent;
                border: none;
                &::after{
                    display: none !important;
                }
               } 
                .dropdown-menu{
                    left: unset !important;
                    right: 0 !important;
                    min-width: 374px;
                    background: var(--noti-bg);
                    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
                    border-radius: 15px;
                    padding: 15px;
                    .upper-div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 15px;
                        h6{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 130%;
                            color: var(--noti-text-head);
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            a{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 130%;
                                color: #FF0083;
                            }
                        }
                    }
                    .bottom-content{
                        .single-noti{
                            background: var(--noti-inactive-bg);
                            border: 1px solid var(--noti-inactive-border);
                            border-radius: 8px;
                            padding: 15px;
                            margin-bottom: 3px;
                            &.active{
                                background: var(--noti-active-bg) !important;
                                border: 1px solid var(--noti-active-border) !important;
                                border-radius: 8px;
                            }
                            h6{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--noti-inner-text);
                                margin-bottom: 6px;
                            }
                            p{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--noti-inner-text1);
                                margin-bottom: 10px;
                            }
                            .laterago{
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 130%;
                                color: var(--noti-inner-text2);
                                &.active{
                                    color: #FF0083 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:992px){
    .main-navbar .navbar .navbarlefticons{
        display: none !important;
    }
    .main-navbar .navbar{
        padding: 25px 0px;
    }
}

.main-head-navbar{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    color: var(--navbar-head-text);
}